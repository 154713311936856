module.exports.CONNECTED = 'connected';
module.exports.DELETE_THING = 'deleteThing';
module.exports.DELETE_THINGS = 'deleteThings';
module.exports.EVENT_OCCURRED = 'eventOccurred';
module.exports.PROPERTY_STATUS = 'propertyStatus';
module.exports.REFRESH_THINGS = 'refreshThings';

module.exports.ThingFormat = {
  INTERACTIVE: 0,
  EXPANDED: 1,
  LINK_ICON: 2,
};
